/* Built In Imports */
// import NextLink from 'next/link';

/* External Imports */
import { Box, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import SectionTitle from '@components/Headings/SectionTitle';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Services */
/* Styles */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**
 * Renders the Architecture Card component
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the page
 * @param {string} lang - Language of the page
 * @param sectionContent.region
 * @param sectionContent.lang
 * @returns {ReactElement} Architecture component
 */
const Architecture = ({ sectionContent, region, lang }) => {
  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,

    customPaging: function (i) {
      return (
        <Text
          cursor="pointer"
          display="inline-block"
          w="12px"
          h="12px"
          bgColor="#fff"
          borderRadius="50%"
        ></Text>
      );
    },
  };

  return (
    <Box p="2.2% 0" w={'100%'}>
      <Box
        // clipPath={{
        //   xl: 'polygon(0 5%, 100% 0%, 100% 100%, 0% 100%)',
        //   lg: 'polygon(0 5%, 100% 0%, 100% 100%, 0% 100%)',
        // }}
        // clipPath={'polygon(0 5%, 100% 0%, 100% 95%, 0% 92%)'}
        p="30px"
        // pt="8vw"
        width={'100%'}
        // bg="#f5f5f5"
        // bg="orange"
        // bg="black"
        h="100%"
        // m="0 auto"
        pl={'4%'}
        textAlign="center"
        pr={'4%'}
      >
        {/* The title and the description of the component */}
        <SectionTitle titleObj={sectionContent[0]} />
        <Box
          fontFamily="FedraSansStd-book"
          fontSize="18px"
          lineHeight="1.61"
          textAlign="left"
          color="#28231e"
          className="event-text"
          w={{ md: '90%', xl: '100%' }}
          m="0 auto"
        >
          <StructuredTextParser
            str={render(sectionContent[1]?.body)}
            region={region}
            lang={lang}
          />
        </Box>

        {/* Here comes the container of the gallery  */}
        {sectionContent[2]?.cards.map((data, index) => {
          return (
            <Box
              display={'flex'}
              flexDir={{
                base: 'column',
                sm: 'column',
                xl: index % 2 == 0 ? 'row' : 'row-reverse',
              }}
              justifyContent="center"
              marginTop="80px"
            >
              <Box
                className="slider description"
                w={{ sm: '85%', base: '85%', lg: '45%', md: '85%', xl: '45%' }}
                height={'auto'}
                // margin="-15px 15px auto 15px"
                m="0 auto"
                color={'black'}
                // textAlign={index % 2 == 0 ? 'right' : 'left'}
                textAlign="center"
              >
                <Box
                  as="h3"
                  fontFamily={'FedraSansStd-A-medium'}
                  fontSize="24px"
                  color="#28231e"
                  lineHeight={'1.61'}
                  mb="5%"
                >
                  {' '}
                  {data.title}
                </Box>
                <Box as="h4" fontSize={'16px'} fontFamily={'FedraSansStd-book'}>
                  <Box className="event-text">
                    <StructuredTextParser str={render(data.description)} />
                  </Box>
                </Box>
              </Box>
              <Box
                // margin="auto 15px"
                margin={'0 auto'}
                boxShadow="0 22px 30px -10px rgb(0 0 0 / 40%)"
                w={{ sm: '85%', base: '85%', lg: '45%', md: '85%', xl: '45%' }}
                // height="auto"
                maxH="271px"
                height="271px"
              >
                <Slider
                  key={index}
                  float="left"
                  w={{ base: '100%', sm: '100%', md: '100%' }}
                  mr="70px"
                  maxH="271px"
                  height="271px"
                  ml={{ base: '0', sm: '0', md: '30px' }}
                  {...settings}
                >
                  {data.thumbnails.map((img, i) => {
                    return (
                      <Box key={i} {...settings}>
                        <LazyLoadImageComponent
                          alt={img?.alt}
                          width={{ md: '100%' }}
                          minW="1px"
                          maxH="271px"
                          height="271px"
                          src={img?.url}
                          title={img?.title}
                          objectFit="cover"
                        />
                      </Box>
                    );
                  })}
                </Slider>
              </Box>
              <style jsx global>
                {`
                  .slick-dots {
                    bottom: 5px;
                  }
                  .slick-dots li {
                    margin: 0;
                  }
                  .slick-dots li.slick-active p {
                    background-color: #f5ac3e;
                  }
                `}
              </style>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default Architecture;
